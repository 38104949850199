<template>
  <div>
    <div v-for="video in list" :key="video.id" class="columns">
      <div class="column is-3" >
        <video controls>
          <source :src="video.link" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <h2><b>{{ video.title }}</b></h2>
      </div>
      <div class="column">
        {{ video.description }}
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'

export default {
  data () {
    return {
      loading: true,
      list: []
    }
  },
  mounted () {
    this.get()
  },
  methods: {
    async get () {
      this.loading = true
      try {
        const response = await ApiService.get('howtovideos?active=1&sort=sort')
        this.list = response.data
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    }
  }
}
</script>
