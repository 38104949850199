<template>
  <div>
    <h2 class="title">Anleitungen</h2>
    <a :href="urlHowToPdf" target="_blank">- TopQ Tool</a>
    <br>
    <h2 class="title">Erklärvideos</h2>
    <HowToVideoList />
  </div>
</template>

<script>
import HowToVideoList from '../components/HowToVideoList.vue'

export default {
  components: {
    HowToVideoList
  },
  data () {
    return {
      urlHowToPdf: `${process.env.VUE_APP_API_BASE_URL}/files/Anleitung_Topq_Tool.pdf`
    }
  }
}
</script>
